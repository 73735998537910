import {Component} from '@angular/core';
import {MsalService} from '@azure/msal-angular';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: false
})
export class LoginComponent {

  constructor(private msalService: MsalService) {
  }

  login() {
    this.msalService.loginRedirect().subscribe();
  }
}
