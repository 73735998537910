import {Component, OnDestroy, OnInit} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {NavigationEnd, Router} from '@angular/router';
import {UserService} from './services/user/user.service';
import {UtilityService} from './services/utility/utility.service';
import {FeatureFlagsService} from "./services/feature-flags/feature-flags.service";
import {RealTimeStatusService} from "./api-services/legacy-milestone-api/real-time-status/real-time-status.service";
import {LoadingState} from "./state/loading-state.enum";
import {
  catchError,
  combineLatest,
  concat,
  concatMap,
  distinctUntilChanged,
  EMPTY,
  filter,
  forkJoin,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
  tap
} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {GeneralState, SetAuthState} from './state/general/general.state';
import {environment} from "../environments/environment";
import {RealTimeStatusModel} from "./api-services/legacy-milestone-api/real-time-status/models/real-time-status.model";
import {
  ITaskManagementStateModel
} from "./shared/components/legacy-task-sidebar/state/models/task-management-state.model";
import {GetStatusesForResources} from "./state/status/status.state";
import {LoadOverviewForHierarchy} from "./shared/components/legacy-task-sidebar/state/task-management.state";
import {ToastrService} from "ngx-toastr";
import {ResourceStatusService} from "./api-services/milestone-api/resource-status/resource-status.service";
import {setUser} from "@sentry/angular";
import {ResourceStatusModel} from "./api-services/milestone-api/resource-status/models/resource-status.model";
import {ResourceTreeModel} from "./api-services/milestone-api/models/hierarchy-definition/resource-tree.model";
import {ReceiveResourceStatus, SetResourceStateConnectionStatus} from "./state/resource-status/resource-status.state";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Sales Portal';

  loadingState: LoadingState = LoadingState.loading;
  hasInitializedFeatureFlags = false;
  readonly LoadingState = LoadingState;
  @Select(GeneralState.loggedInUser) user$!: Observable<any | null>;

  loginState$ = new Subject<boolean>();

  componentDestroyed$ = new Subject<void>()

  constructor(private readonly resourceStatusService: ResourceStatusService, private toastr: ToastrService, private router: Router, private msalService: MsalService, private userService: UserService, private utility: UtilityService, private flag: FeatureFlagsService, private realTimeStatusService: RealTimeStatusService, private store: Store) {
  }

  ngOnInit() {
    const navigationEndObservable = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event) => event as NavigationEnd)
    );

    this.user$.pipe(
      takeUntil(this.componentDestroyed$),
      distinctUntilChanged()
    ).subscribe(user => {
      if (user && !this.hasInitializedFeatureFlags) {
        this.hasInitializedFeatureFlags = true;
        this.flag.initialize(environment.ldClientId).subscribe(() => {
          this.flag.identify({
            id: user.id,
            name: user.displayName,
            email: user.mail
          }).subscribe();
        });
      }

      if (user && environment.production) {
        setUser({
          id: user.id,
          username: user.displayName,
          email: user.mail
        });
      }
    });

    combineLatest([navigationEndObservable, this.loginState$]).pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(([event, loggedIn]) => {
        if (loggedIn && (event.url === '/login' || event.urlAfterRedirects === '/login')) {
          this.router.navigate(['dashboards/task-dashboard']);
        }
      }
    );

    this.msalService.handleRedirectObservable().pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((result) => {
      if (result) {
        this.msalService.instance.setActiveAccount(result.account);

        this.loadingState = LoadingState.loading;
        this.makePrerequisiteRequests(result.account!.localAccountId, true);
      }

      let account = this.msalService.instance.getActiveAccount();

      // If there is no active account, but there are accounts in the cache, set the first account as the active account.
      if (!account && this.msalService.instance.getAllAccounts().length > 0) {
        this.msalService.instance.setActiveAccount(this.msalService.instance.getAllAccounts()[0]);

        account = this.msalService.instance.getActiveAccount();
      }

      if (account) {
        this.makePrerequisiteRequests(account.localAccountId, false);
      }

      this.loginState$.next(!!account);

      // If there is no active account, redirect to login page.
      if (!account) {
        this.loadingState = LoadingState.loaded;
        this.router.navigate(['login']);
      }
    });
  }

  ngOnDestroy() {
    this.flag.tareDown().subscribe();
    this.realTimeStatusService.closeConnection().subscribe();

    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private makePrerequisiteRequests(userId: string, navigateToLandingPage: boolean): void {
    this.loadingState = LoadingState.loading;
    forkJoin([this.userService.getJwtToken(), this.userService.saveLocalUser({UserId: userId}), this.msalService.acquireTokenSilent({
      scopes: environment.b2cScopes,
    })]).pipe(
      takeUntil(this.componentDestroyed$),
      catchError(() => {
        this.loadingState = LoadingState.error;

        this.userService.logout();
        this.toastr.error('Error establishing connection to identity service. Please refresh the page and try again.');

        return EMPTY;
      })
    ).subscribe(([token, _, msalToken]) => {
      // TODO: Remove references to tokens via localStorage.
      const jwtToken = token.results;
      localStorage.setItem('jwtToken', JSON.stringify(jwtToken));

      this.userService.getCurrentUserProfile().subscribe((profile) => {
        // TODO: Remove references to tokens via localStorage.
        localStorage.setItem('user_profile', JSON.stringify(profile.results));
        localStorage.setItem('idToken', JSON.stringify(msalToken.accessToken));
        localStorage.setItem('b2CUserId', JSON.stringify(msalToken.uniqueId));
        localStorage.setItem('userId', JSON.stringify(profile.results.id));

        this.utility.setUserPermissions();
        this.store.dispatch(new SetAuthState({
          user: profile.results,
          token: token.results,
          msalToken: msalToken.accessToken,
        }));

        this.configureLegacyRealTimeStatuses(msalToken.uniqueId, msalToken.accessToken);
        this.configureRealTimeStatuses(msalToken.accessToken);

        this.utility.determineUserLandingPage(profile.results);

        this.loadingState = LoadingState.loaded;

        if (navigateToLandingPage) {
          this.router.navigate(['/']);
        }
      });
    });
  }

  private configureRealTimeStatuses(token: string): void {
    concat(
      this.flag.initialize$.pipe(take(1)),
      this.flag.variation('show-new-milestone-canary-release', false).pipe(
        filter((x) => x),
        distinctUntilChanged(),
        tap(() => {
          this.resourceStatusService.connectionStatus$.subscribe((status) => {
            this.store.dispatch(new SetResourceStateConnectionStatus(status));
          });
        }),
        concatMap(() => this.resourceStatusService.establishConnection(token)),
        tap(() => {
          // Handler for resource status updates.
          this.resourceStatusService.listenMultiple<[ResourceStatusModel, ResourceTreeModel]>('ReceiveResourceStatus').pipe(
            catchError(() => {
              this.toastr.error('Error establishing connection to (new) real-time status service.');
              return EMPTY;
            }),
          ).subscribe(([status, tree]) => {
            this.store.dispatch(new ReceiveResourceStatus(tree, status));
          });
        })
      ),
    ).subscribe();
  }

  // TODO: Remove
  private configureLegacyRealTimeStatuses(userId: string, token: string): void {
    this.realTimeStatusService.establishConnection(token).pipe(
      catchError(() => {
        this.toastr.error('Error establishing connection to real-time status service. Contract/Ticket/PO statuses may not accurately reflect real-time data.');
        return EMPTY;
      })
    ).subscribe(() => {
      this.realTimeStatusService.listen<RealTimeStatusModel>('RealTimeStatusUpdate').subscribe(data => {
        if (data.changeCausedByUserId === userId) {
          this.toastr.info(`Task "${data.affectedTask.name}" has been automatically completed.`, 'Automatic Task Completion');
          this.store.selectSnapshot(state => {
            const taskManagementState: ITaskManagementStateModel | null = state.taskManagement;

            this.store.dispatch(new GetStatusesForResources([data.resourceHierarchy]));

            // Determine if the task management state is initialized
            if (!taskManagementState || !taskManagementState.hierarchy) {
              return;
            }

            // Reload the overview if the hierarchy matches the affected resource
            if (taskManagementState.hierarchy.contractId === data.resourceHierarchy.contractId && taskManagementState.hierarchy.ticketId === data.resourceHierarchy.ticketId && taskManagementState.hierarchy.purchaseOrderId === data.resourceHierarchy.purchaseOrderId) {
              this.store.dispatch(new LoadOverviewForHierarchy(taskManagementState.hierarchy, true));
            }
          });
        }
      });
    });
  }
}
